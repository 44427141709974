import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { CONNECT_SERVICE_BASE_URL, PROVIDER_HEADERS, PROVIDER_HEADERS_GYMPIK } from '../constants/env';
import { getStorage } from '../utils/storage';
import { getAccessToken, getUserInfo } from '../utils/session';
export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<string>;
  user?: { displayName: any; photoURL: any; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  } as StateContextType;


  contextValue = {
    ...contextValue,
    getToken: async (identity, roomName) => {

      const endpoint = CONNECT_SERVICE_BASE_URL + 'v1/meeting/twilio';

      let params: any = {
        appointment_id: getStorage("appointment"),
        appointment_source: "cross",
        open: false,
        join: true,
        force: true
      }

      let APP_HEADERS = {}
      switch (getStorage('type')) {
        case 'patient':
          APP_HEADERS = {
            ...PROVIDER_HEADERS,
            "rg_source": getStorage('source'),
            "rg_user_type": getStorage('type'),
            "rg_user_id": getUserInfo().detail.uuid,
            "authorization": getAccessToken()
          }
          break;

        case 'traqade':
          APP_HEADERS = {
            ...PROVIDER_HEADERS_GYMPIK,
          }
          params.appointment_source = "traqade";
          params.open = true;
          break;

        default:
          APP_HEADERS = {
            ...PROVIDER_HEADERS,
            "rg_source": getStorage('source'),
            "rg_user_type": getStorage('type'),
            "rg_user_id": getStorage('user_id'),
            "authorization": getStorage('access_token')
          }
          break;
      }

      params = Object.keys(params).map(key => key + '=' + params[key]).join('&');

      return fetch(`${endpoint}?${params}`, {
        method: "GET",
        headers: {
          ...APP_HEADERS,
        },
      }).then(response => response.json());

    },
  };


  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then((res: any) => {
        setIsFetching(false);
        console.table(res.data.user_token)
        return res.data.user_token;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
