import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import PreflightTest from './PreflightTest/PreflightTest';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Video from 'twilio-video';
import Typography from '@material-ui/core/Typography/Typography';
import { useLocation } from "react-router-dom";
import { createSession, getStorage, removeStorage, setStorage } from '../../utils/storage';
import { PROVIDER_HEADERS, USER_SERVICE_BASE_URL } from '../../constants/env';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName } = useParams();
  const location = useLocation();

  const params = useParams();

  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    const access_token = new URLSearchParams(location.search).get('access_token');
    const type = new URLSearchParams(location.search).get('type');
    const appointment_id = new URLSearchParams(location.search).get('appointment_id');
    const app = new URLSearchParams(location.search).get('app');
    const userId = new URLSearchParams(location.search).get('user_id');

    let appType = "reachweb";

    switch (app) {
      case "consult": 
        appType = "consult_web";
        break;
      case "doctor":
        appType = "cross-doctor-web";
        break;
    }

    /** Clear existing state */
    removeStorage();

    setStorage('type', type);
    setStorage('source', appType);
    setStorage('appointment', appointment_id);

    if (getStorage('type') != 'patient') {
      setStorage('access_token', access_token);
      setStorage('user_id', userId);
      setStep(Steps.deviceSelectionStep);
    } else {
      getUserProfile(access_token);
    }

  }, []);

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  const SubContent = (
    <>
      {Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  const getUserProfile = (token: any) => {
    const endpoint = USER_SERVICE_BASE_URL + 'account/profile';
    return fetch(`${endpoint}`, {
      method: "GET",
      headers: {
        ...PROVIDER_HEADERS,
        "rg_source": getStorage('source'),
        "rg_user_type": getStorage('type'),
        "authorization": token
      },
    })
    .then(response => response.json())
    .then(result => {
      createSession(result.data);
      setStep(Steps.deviceSelectionStep);
    })
    .catch(error => console.log('error', error));
  }


  return (
    <IntroContainer subContent={step === Steps.deviceSelectionStep && SubContent}>
      {step === Steps.roomNameStep && (
        // <RoomNameScreen
        //   name={name}
        //   roomName={roomName}
        //   setName={setName}
        //   setRoomName={setRoomName}
        //   handleSubmit={handleSubmit}
        // />
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
          <CircularProgress size={56} color="primary" />
          <Typography variant="h5" style={{ marginTop: '1em'}}>
            Joining
          </Typography>
          <Typography variant="body1">
            Please wait....   
          </Typography>
        </div>
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
