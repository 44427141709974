// const process.env = process.process.env;

const VERSION = require('../../package.json').version;

const APP_URL = process.env.APP_URL;
const PROVIDER_HEADERS:any = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    rg_project_id: process.env.REACT_APP_PROJECT_ID,
    rg_application: process.env.REACT_APP_APPLICATION,
    rg_application_version: process.env.REACT_APP_APPLICATION_VERSION,
};

const PROVIDER_HEADERS_GYMPIK: any = {
    client_id: process.env.REACT_APP_GYMPIK_CLIENT_ID,
    rg_project_id: process.env.REACT_APP_GYMPIK_PROJECT_ID,
    rg_source: process.env.REACT_APP_GYMPIK_SOURCE,
    rg_application: process.env.REACT_APP_GYMPIK_APPLICATION,
    rg_application_version: process.env.REACT_APP_GYMPIK_APPLICATION_VERSION,
};


const USER_SERVICE_BASE_URL = process.env.REACT_APP_BASE_URL_USER_SERVICE;
const CONNECT_SERVICE_BASE_URL = process.env.REACT_APP_BASE_URL_CONNECT;

export {
    VERSION,
    APP_URL,
    PROVIDER_HEADERS,
    PROVIDER_HEADERS_GYMPIK,
    USER_SERVICE_BASE_URL,
    CONNECT_SERVICE_BASE_URL,
};
