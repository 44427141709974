import { APP_URL } from "../constants/env";

const cookieDomain = '.' + APP_URL;

export function setStorage(key: string, value: any) {
    if (localStorage) {
        localStorage.setItem(key, btoa(JSON.stringify(value)));
    }
}

export function getStorage(key: string): any {
    if (localStorage) {
        let exist = localStorage.getItem(key);
        if (exist) {
            return JSON.parse(atob(exist));
        } else {
            return null
        }
    }
}

export function removeStorage(key?: string) {
    if (key) {
        if (localStorage) localStorage.removeItem(key);
    } else {
        if (localStorage) localStorage.clear();
    }
}

export function getCookie(name: any) {
    var value = "; " + document.cookie;
    var parts:any = value.split("; " + name + "=");
    if (parts.length == 2) return unescape(parts.pop().split(";").shift());
}

export function setCookie(params: any) {
    var name = params.name,
        value = params.value,
        expireDays = params.days,
        expireHours = params.hours,
        expireMinutes = params.minutes,
        expireSeconds = params.seconds;

    if (!params.maxAge) {
        var expireDate = new Date();
        if (params.expireDate)
            expireDate = params.expireDate;
        if (expireDays)
            expireDate.setDate(expireDate.getDate() + expireDays);
        if (expireHours)
            expireDate.setHours(expireDate.getHours() + expireHours);
        if (expireMinutes)
            expireDate.setMinutes(expireDate.getMinutes() + expireMinutes);
        if (expireSeconds)
            expireDate.setSeconds(expireDate.getSeconds() + expireSeconds);

        document.cookie = name + "=" + escape(value) +
            ";domain=" + cookieDomain +
            ";path=/" +
            ";expires=" + expireDate.toUTCString();

    } else {

        document.cookie = name + "=" + escape(value) +
            ";domain=" + cookieDomain +
            ";path=/" +
            ";max-age=" + 31536000;
    }
}

export function deleteCookie(name: string) {
    setCookie({ name: name, value: "", seconds: 1 });
}

export function deleteAllCookies() {
    let cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        setCookie({ name: name, value: "", seconds: 1 });
    }
}

export function createSession(responseData: any, onlyData: boolean = false) {

    const users = responseData.user.accounts;

    let account: any;
    users.forEach((user:any) => {
        if (user.account_manager.display === 'cognito') {
            account = user;
        }
    });

    const refreshToken = account.detail.refresh_token;
    const accessToken = account.detail.access_token;

    // Setting Refresh Token in cookie
    setCookie({ name: 'rgrtpt', value: accessToken, maxAge: 1 });

    const overAllUser: any = {
        accessToken: accessToken,
        refreshToken: refreshToken,
        user: account
    };

    setStorage('u', overAllUser);
    setStorage('utb', responseData);

}