import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { CONNECT_SERVICE_BASE_URL, PROVIDER_HEADERS } from '../../../constants/env';
import { getStorage } from '../../../utils/storage';
import { getAccessToken, getUserInfo } from '../../../utils/session';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room, isSharingScreen, toggleScreenShare, removeLocalAudioTrack, removeLocalVideoTrack } = useVideoContext();

  const handleClick = () => {
    
    if (isSharingScreen) {
      toggleScreenShare();
    }

    removeLocalAudioTrack();
    removeLocalVideoTrack();

    if (getStorage('type') == 'practitioner') {
      endServerCall();
      room.disconnect();
    } else {
      room.disconnect();
    }

  };

  const endServerCall = () => {

    const endpoint = CONNECT_SERVICE_BASE_URL + 'v1/meeting/twilio';

    let params: any = {
      appointment_id: getStorage("appointment"),
      appointment_source: "cross",
      field: "end",
    }

    params = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    const raw = JSON.stringify({ "value": true });
    const APP_HEADERS = {
      "Content-Type": "application/json",
      "rg_source": getStorage('source'),
      "rg_user_type": getStorage('type'),
      "rg_user_id": getStorage('user_id'),
      "Authorization": getStorage('access_token')
    }

    return fetch(`${endpoint}?${params}`, {
      method: "PATCH",
      body: raw,
      headers: {
        ...PROVIDER_HEADERS,
        ...APP_HEADERS,
      },
    }).then(response => response.text())
    .then(result => console.log('success', result))
    .catch(error => console.log('error', error));
  }

  return (
    <Button onClick={handleClick} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
