import { getStorage } from "./storage";

export function getAccessToken() {
    if (localStorage) {
        const user = getStorage('u');

        if (user) {
            return user.accessToken
        } else {
            return null;
        }
    }
}

export function getRefreshToken() {
    if (localStorage) {
        const user = getStorage('u');

        if (user) {
            return user.refreshToken
        }
    }
}

export function getUserInfo() {
    if (localStorage) {
        const data = getStorage('u');
        if (data != null && data.user) {
            return data.user
        } else {
            return null;
        }
    }
}

export function getCurrentPerson() {
    if (localStorage) {
        const data = getStorage('person');
        if (data !== null && data) {
            return data
        } else {
            return null;
        }
    }
}



export function getFullUserInfo() {
    if (localStorage) {
        const data = getStorage('utb');
        if (data !== null && data) {
            return data
        } else {
            return null;
        }
    }
}

